@import "../../uiKit/assets/colors/colors.scss";

.communityCardWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $gray010;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 16px;
  .communityCardRadio {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
