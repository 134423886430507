@import "../../uiKit/assets/colors/colors.scss";

.customInput {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  position: relative;
  label {
    font-size: 14px;
    position: relative;
  }
  input {
    &::placeholder {
      color: $gray900;
    }
  }
}

.label {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: $gray650;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.startAdornment {
  padding-right: 12px;
  display: flex;
  align-items: center;
}

.endAdornment {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.customPhoneInput {
  width: 100%;
  border: 1px solid;
  height: 40px;
  padding: 0 20px;
  background: $gray010;
  border-color: $gray300;
  border-bottom: 1px solid $gray500;
  border-radius: 10px !important;
  margin-bottom: 28px;
  text-transform: none;
  display: inline-flex !important;
  select {
    width: 100%;
    text-transform: none;
    background: $gray010;
    color: $gray800;
    border-color: $gray300;
    height: 40px;
    padding: 0 20px;
    font-family: Poppins;
  }
  input {
    border: none;
    font-family: Poppins;
    font-size: 14px;
    color: $gray800;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: $gray500;
    }
  }
}
