
.mobileBalance {
  width: max-content;
  display: flex;
  cursor: pointer;
}

@media (max-width: 900px) {
  .mobileBalance {
    padding: 10px;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
    border-radius: 12px;
    align-items: center;
    width: 100%;
  }
}
