.titleInputWrapper {
  margin: 24px 40px 0 40px;
  max-width: 664px;
  width: 100%;

  .inputGroup {
    display: flex;

    .customInput {
      margin-bottom: 24px;
      width: 50%;

      &:first-child {
        margin-right: 24px;
      }
    }
  }

  .draftWrapper {
    display: flex;
    width: 100%;
  }

  .content {
    padding-left: 24px;
    padding-top: 52px;

    .customInput {
      margin-bottom: 0;
    }

    .suggestButton {
      width: 100%;
      max-width: 223px;
    }
  }

  .createCampaignButtons {
    margin-top: 32px;
    display: flex;
    margin-bottom: 96px;
    width: max-content;
  }

  .suggestedUserInfo {
    padding: 16px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
    border-radius: 12px;
    margin-top: 22px;
  }
}



@media (min-width: 900px) and (max-width: 1200px) {
  .titleInputWrapper {
    max-width: 616px;
    margin: 24px;
  }
}

@media (max-width: 900px) {
  .titleInputWrapper {
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);

    .inputGroup {
      flex-direction: column;

      .customInput {
        width: 100%;

        &:first-child {
          margin-right: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .draftWrapper {
      flex-direction: column;
    }

    .createCampaignButtons {
      flex-direction: column;
      margin-top: 24px;
      margin-bottom: 64px;
      width: 100%;
    }

    .content {
      padding: 0;

      .divider {
        display: none;
      }

      .suggestButton {
        max-width: 100%;
        padding-bottom: 60px;
      }
    }

    .suggestedUserInfo{
      margin-top: 24px;
    }
  }
}