.button {
  button {
    width: 100%;
    height: 40px;
    align-items: center;
    padding: 10px 20px 10px 20px;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
  }
}

.copyLink {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  cursor: pointer;
}

.marginBottom70To0 {
  margin-bottom: 70px;
}

@media (max-width: 900px) {
  .marginBottom70To0 {
    margin-bottom: 0;
  }
  .bottom24 {
    margin-bottom: 24px;
  }
}
