@import "../../uiKit/assets/colors/colors.scss";

.addMemberWrapper {
  width: 464px;
  margin-top: 16px;
  margin-left: 40px;
  background-color: $gray050;
  padding: 48px;
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
  border-radius: 12px;

  .trumpet {
    display: flex;
    justify-content: space-around;
    padding: 20px 69px 48px 69px;
  }
}

.addMemberFormWrapper {
  width: 656px;
  margin-top: 16px;
  margin-left: 40px;
  padding-top: 40px;
}

.addMemberFormWrapperSingle {
  width: max-content;
  margin-top: 16px;
  margin-left: 40px;
  padding-top: 40px;
}

.shareMessageMobile{
  display: none;
}

.shareMessage {
  position: relative;
}

@media (max-width: 1200px) {
  .addMemberFormWrapper {
    width: calc(100% - 80px);
  }
}

@media (max-width: 900px) {
  .addMemberWrapper {
    width: calc(100% - 48px);
    margin-left: 24px;
    background-color: $blue010;
    padding: 0;
    box-shadow: none;
    margin-bottom: 60px;

    .trumpet {
      display: none;
    }

    .shareMessage {
      padding: 16px;
      background: #FFFFFF;
      box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
      border-radius: 16px;
      margin-bottom: 16px;
    }
  }
  
  .addMemberFormWrapperSingle {
    width: calc(100% - 48px);
    padding: 0;
    margin: 8px 24px 60px 24px;
    .inputWrapper{
      box-shadow: none;
      background-color: transparent;
    }
  }

  .copyToClipboard{
    display: none;
  }

  .shareMessageMobile{
    display: flex;
    width: 100%;
  }

  .addMemberFormWrapper {
    width: calc(100% - 48px);
    padding: 0;
    margin: 8px 24px 60px 24px;
  }

}