$black: #000000;

// grey
$gray010: #ffffff;
$gray050: #fafafa;
$gray100: #f4f4f4;
$gray300: #dfdddd;
$gray500: #bbbbbc;
$gray550: #a3a3a3;
$gray600: #8f8f92;
$gray650: #7a7a7a;
$gray800: #4a4a4a;
$gray900: #3c3c3f;

// blue
$blue010: #f1f8ff;
$blue020: #d0e7ff;
$blue050: #b7dbff;
$blue100: #7dbeff;
$blue300: #379aff;
$blue500: #2087ef;
$blue600: #0671df;
$blue800: #045bb4;
$blue900: #054b93;

// red
$red100: #ffeaea;
$red500: #ffa6a6;
$red600: #ff5c5c;
$red800: #ff4444;
$red900: #e32020;

// green
$green010: #dfffef;
$green050: #b7f0d4;
$green100: #06c270;
$green300: #05a660;

//yellow
$yellow100: #fffadf;
$yellow300: #ffe146;

//pink

$pink: #e1d3ff;
