.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 24px 0 40px;
  .menuHeader{
      display: flex;
      align-items: center;
      cursor: pointer;
  }
}

@media (max-width: 900px){
  .menu{
    padding-left: 24px;
    padding-right: 30px;
  }
}
