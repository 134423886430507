@import "../../../uiKit/assets/colors/colors.scss";

.myCommunitiesWrapper {
  margin: 32px 40px 40px 40px;
  max-width: 1100px;
  width: calc(100% - 80px);
  border-radius: 12px;
  .contentWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .mobileList {
      display: none;
    }
  }
  .myCommunitiesTableWrapper {
    position: relative;
    background-color: $gray010;
    border-radius: 10px;
  }
}

@media (max-width: 1200px) {
  .myCommunitiesWrapper {
    margin: 26px 24px 60px 24px;
    width: calc(100% - 48px);
    .contentWrapper {
      flex-direction: column;
      .mobileList {
        display: flex;
        flex-direction: column;
      }
    }
    .myCommunitiesTableWrapper {
      display: none;
    }
  }
}
