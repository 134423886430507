.membersWrapper {
  .adminsTableWrapper {
    position: relative;
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
    background-color: #ffffff;
    border-radius: 12px;
  }
  .adminsList{
    display: none;
  }
}

@media (max-width: 1200px) {
  .membersWrapper {
    .adminsTableWrapper {
      display: none;
    }
    .adminsList {
      display: flex;
      flex-direction: column;
      margin: 24px 0;
      .adminsListRow {
        display: flex;
        padding: 16px 16px 12px;
        background-color: #ffffff;
        border-bottom: 1px solid #f4f4f4;
        justify-content: flex-start;
      }
    }
  }
}
