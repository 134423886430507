@import "../../uiKit/assets/colors/colors.scss";

.tableHeader {
  th {
    border-bottom: 1px solid $blue300;
    font-size: 14px;
    font-weight: 600;
    padding: 14px 0 12px 0;
  }
}

.customTablePaginationWrapper {
  background-color: $gray010;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;

  border-radius: 0 0 12px 12px;

  .flexAlign {
    display: flex;
    align-items: center;
    ul {
      flex-direction: row;
    }
  }
}

.mobilePaginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.pagination {
  button {
    background-color: $gray010;
    border: 1px solid $blue050;
    border-radius: 10px;
    color: $blue500;
    width: 48px;
    height: 40px;
    align-items: center;
    padding: 10px 20px;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    svg {
      color: $blue500;
    }
  }
  .Mui-selected {
    background-color: $blue300 !important;
    color: $gray010;
    border-color: $blue300;
  }
}
