@import "../../../uiKit/assets/colors/colors.scss";

.paymentPreferencesWrapper {
  margin: 24px 40px 0 40px;
  padding: 48px;
  width: 448px;
  background-color: $gray010;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
  position: relative;

  .cardTamplete {
    max-width: 352px;
    min-width: 312px;
    width: 100%;
    height: 180px;
    background-size: cover;
    margin-bottom: 16px;
    padding: 22px 30px 0 38px;
    margin-left: -8px;
    margin-top: 22px;
    border-radius: 10px;

    .titleWrapperStripe {
      height: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .customElement {
      border: 1px solid;
      border-radius: 10px;
      text-transform: none;
      background: $gray010;
      color: $gray800;
      border-color: $gray300;
      height: 40px;
      padding: 10px 20px;
      font-family: Poppins;
      border-bottom: 1px solid $gray500;
      font-size: 16px;
      margin-bottom: 12px;
    }

    .width132 {
      width: 132px;
    }

    .customInputCVC {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .recurringList {
    position: relative;
    margin: 32px 0 0 0;

    .subscriptionItem {
      margin: 16px 0;
    }
  }
}

.addressLine {
  width: 100%;
  border: 1px solid;
  border-radius: 10px !important;
  text-transform: none;
  background: $gray010;
  color: $gray800;
  border-color: $gray300;
  height: 40px;
  padding: 0 20px;
  font-family: Poppins;
  border-bottom: 1px solid $gray500;
  font-size: 14px;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  position: relative;

  input {
    margin-left: 16px;
    width: 100%;
    border: none;
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $gray500;
    }

  }
}

.width100 {
  width: 100%;
}

.recivingSaveButton {
  margin-top: 16px;
  width: 100%;
}

@media (max-width: 900px) {
  .paymentPreferencesWrapper {
    margin: 24px;
    margin-bottom: 64px;
    padding: 0;
    background-color: transparent;
    width: calc(100% - 48px);
    box-shadow: none;

    .cardTamplete {
      padding: 16px 16px 0 24px;
      margin-bottom: 0;
    }

    .recurringList {
      margin: 16px 0 0 0;

      .subscriptionItem {
        background-color: $gray010;
        border-radius: 12px;
        box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
        padding: 16px;
      }
    }
  }

  #methodBackground {
    background: transparent;
    height: 100%;
  }
}

@media (max-width: 320px) {
  .paymentPreferencesWrapper {
    .cardTamplete {
      min-width: 272px;

      .customInputCVC {
        .width132 {
          width: 96px;
        }
      }

      .titleWrapperStripe {
        height: 20px;
      }
    }
  }
}