@import "../../uiKit/assets/colors/colors.scss";

.landingBackground {
  width: 100%;
  min-height: 100vh;
  position: relative;

  .logoWrapper {
    position: absolute;
    top: 24px;
    left: 16px;
    width: 168px;
    height: 36px;
  }

  .contentWrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .landingContentWrapper {
      z-index: 100;
      width: 464px;
      max-width: 464px;
      padding: 48px;
      background-color: $gray010;
      box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
      border-radius: 12px;
    }
  }

  .notFoundWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
  }
}

@media (max-width: 600px) {
  .logoWrapper {
    display: none;
  }

  .landingBackground {
    .contentWrapper {
      .landingContentWrapper {
        width: 100%;
        padding: 40px 24px 0 24px;
        background-color: transparent;
        box-shadow: none;
        border-radius: 12px;
      }
    }
  }
}