@import "../../../uiKit/assets/colors/colors.scss";

.cards {
  display: flex;
  margin-bottom: 32px;
  justify-content: space-between;

  .cardWrapper {
    max-width: 170px;
    min-width: 148px;
    width: 100%;
    min-height: 238px;
    max-height: 275px;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
    background-color: $gray010;
    cursor: pointer;
    &:first-child {
      margin-right: 28px;
    }

    .cardRadioButton {
      width: 20px;
      height: 20px;
      align-items: center;
      display: flex;
      margin: 10px 0 0 10px;
    }
  }

  .selectedCard {
    box-shadow: none;
    border: 2px solid $blue050;
  }

  .greetingWrapper {
    padding: 0 40px;
    .greeting {
      text-align: center;
      margin-top: 16px;
    }

    .greetingText {
      margin-top: 15px;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .memberText {
      margin-top: 15px;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
}

@media (max-width: 600px) {
  .cards {
    .cardWrapper {
      &:first-child {
        margin-right: 16px;
      }
    }

    .greetingWrapper {
      padding: 0 30px;
      .greeting {
        margin-top: 8px;
      }
      .greetingText {
        margin-bottom: 19px;
      }
      .memberText {
        margin-bottom: 19px;
      }
    }
  }

  .mobileHeight {
    min-height: 100vh;
  }
}
