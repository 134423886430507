.feedback {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .feedbackContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    .feedbackImageContainer {
      margin: 0 auto 32px auto;
    }
  }
}

.description {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
