@import "../../../uiKit/assets/colors/colors.scss";

.wrapperAlternate {
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: center;
    position: relative;
    background-image: url("../../../uiKit/assets/png/BGAlternate.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 874px;

    .container {
        max-width: 1440px;
        width: 100%;
        height: 100%;

        .mainSection {
            padding: 0 162px 0 169px;
            position: relative;
            height: 874px;
            background-image: url("../../../uiKit/assets/svg/blueShape.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-color: #fff;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 46px;

                button {
                    width: 168px;
                    height: 49px;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500;
                }
            }

            .mainContent {
                margin-top: 91px;

                .content {
                    display: flex;
                    flex-direction: column;
                    max-width: 543px;
                    width: 100%;

                    .mainTitle {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 32px;
                        margin-bottom: 20px;

                        span {
                            font-size: 48px;
                            line-height: 56px;
                        }
                    }

                    button {
                        width: 168px;
                        height: 49px;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 500;
                    }
                }

                .signUpImage {
                    max-width: 566px;
                    min-width: 273px;
                    width: 100%;
                    max-height: 490px;
                    min-height: 201px;
                    height: 100%;
                }

                .signUpButton {
                    display: none;
                }
            }

            .blueLine {
                position: absolute;
                bottom: 36px;
                left: 0;
                width: 100%;
                transform: rotate(2deg);

                img {
                    width: 100%;
                }
            }

            .blueShapeMobile {
                display: none;
            }
        }



        .stepsSection {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .stepsIntro {
                width: calc(100% - 330px);
                min-height: 622px;
                height: max-content;
                margin: 222px 166.5px 0 163.5px;
                background: linear-gradient(72.57deg, #B7DBFF 11.7%, rgba(227, 239, 255, 0.43) 67.03%);
                border-radius: 50px;
                display: flex;
                position: relative;

                .blueCircle {
                    position: absolute;
                    width: 87px;
                    height: 87px;
                    top: -102px;
                    right: 12%;
                    background: $blue300;
                    border-radius: 50%;
                    z-index: 10;
                }

                .grayBlueCircle {
                    position: absolute;
                    width: 66.73px;
                    height: 68.03px;
                    top: 78.83px;
                    left: 50%;
                    background: #b7dbff;
                    border-radius: 50%;
                    z-index: 10;
                }

                .yellowCircle {
                    position: absolute;
                    width: 32px;
                    height: 32px;
                    top: 103px;
                    right: 4%;
                    background: #fed43e;
                    z-index: 10;
                    border-radius: 50%;
                }

                .blueLittleCircle {
                    position: absolute;
                    width: 32.83px;
                    height: 33.48px;
                    background: $blue100;
                    border-radius: 50%;
                    bottom: -16.86px;
                    left: 41%;
                    z-index: 10;
                }

                .stepsTitleMobile {
                    display: none;
                }

                img {
                    margin: 114px 72px 73.5px 58px;
                }

                .stepsContent {
                    padding-top: 67px;

                    .stepsTexts {
                        padding-right: 90px;
                        padding-top: 34px;
                    }
                }
            }

            .steps {
                position: relative;
                margin-top: 64px;
                width: 924px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .vector01 {
                    position: absolute;
                    top: 0px;
                    left: 11%;
                    height: 100%;
                }

                .vector01Mobile {
                    display: none;
                }

                .vector02 {
                    position: absolute;
                    right: 0;
                    bottom: -12px;
                    height: 100%;
                }

                .vector02Mobile {
                    display: none;
                }

                .vector03 {
                    position: absolute;
                    bottom: 1%;
                    left: 0;
                    height: 100%;
                }

                .vector03Mobile {
                    display: none;
                }

                .vector04 {
                    position: absolute;
                    bottom: 0;
                    left: 11%;
                    height: 100%;
                }

                .vector04Mobile {
                    display: none;
                }
            }

            button {
                width: 168px;
                height: 49px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                margin-top: 75px;
                margin-bottom: 84px;
            }

            .footerAlternate {
                padding-bottom: 60px;
                width: 100%;

                .footerLogo {
                    width: 172px;
                    margin: 0px 166px 0px 164px;
                    cursor: pointer;
                }

                .dividerFooter {
                    border: 1px solid rgb(223, 221, 221);
                    background-color: rgb(223, 221, 221);
                    height: 2px;
                    margin: 31px 166px 31px 164px;
                    width: calc(100% - 330px);
                }

                .dividerFooterMobile {
                    display: none;
                }

                .connections {
                    display: flex;
                    justify-content: space-between;
                    margin: 0px 166px 0px 164px;
                    width: calc(100% - 330px);
                }

                .connectionsMobile {
                    display: none;
                }
            }
        }

    }
}

@media (max-width: 1440px) {
    .wrapperAlternate {
        .container {

            .stepsSection {
                .stepsIntro {
                    flex-direction: column-reverse;
                    align-items: center;
                    justify-content: center;
                    height: max-content;

                    .stepsContent {
                        padding-top: 0;
                        padding-left: 24px;
                    }

                    .stepsTitleMobile {
                        display: block;
                    }

                    .stepsTitleDesktop {
                        display: none;
                    }

                    img {
                        width: 251px;
                        height: 259px;
                        margin-top: 0;
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .wrapperAlternate {
        .container {
            .mainSection {
                padding: 0 24px;
            }

            .stepsSection {
                .stepsIntro {
                    width: calc(100% - 48px);
                    height: max-content;
                    margin: 24px;

                    .blueCircle {
                        top: -37px;
                        right: 5px;
                    }

                    .yellowCircle {
                        display: none;
                    }

                    img {
                        margin-bottom: 24px;
                    }

                    .stepsContent {
                        .stepsTexts {
                            padding-top: 61px;
                            padding-right: 31px;
                        }
                    }
                }

                .steps {
                    width: 327px;

                    .vector01 {
                        display: none;
                    }

                    .vector01Mobile {
                        position: absolute;
                        top: -15px;
                        left: 13%;
                        display: block;
                        height: 100%;
                        width: 284.5px;
                    }

                    .vector02 {
                        display: none;
                    }

                    .vector02Mobile {
                        position: absolute;
                        top: 64px;
                        left: 12%;
                        display: block;
                        height: 100%;
                        width: 190px;
                    }

                    .vector03 {
                        display: none;
                    }

                    .vector03Mobile {
                        position: absolute;
                        top: 24px;
                        right: 0;
                        display: block;
                        height: 100%;
                        width: 163px;
                    }

                    .vector04 {
                        display: none;
                    }

                    .vector04Mobile {
                        position: absolute;
                        top: 82px;
                        right: 0;
                        display: block;
                        height: 100%;
                        width: 312px;
                    }
                }

                .footerAlternate {
                    .footerLogo {
                        margin: 24px;
                    }

                    .dividerFooter {
                        margin: 24px;
                        width: calc(100% - 48px);
                    }

                    .connectionsMobile {
                        display: flex;
                        margin: 0 24px;
                        justify-content: space-between;
                        width: calc(100% - 48px);
                    }

                    .connections {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .App {
        .wrapperAlternate {
            background-image: url("../../../uiKit/assets/png/BGmobileAlternate.png");

            .container {
                .mainSection {

                    background-size: cover;

                    .header {
                        >.button {
                            display: none;
                        }
                    }

                    .mainContent {
                        flex-direction: column;
                        align-items: center;
                        width: 100%;

                        .content {
                            max-width: max-content;

                            button {
                                display: none;
                            }
                        }

                        .signUpImage {
                            width: 273px;
                            height: 201px;
                        }

                        .signUpButton {
                            display: flex;
                            justify-content: center;

                            button {
                                width: 312px;
                                margin-top: 24px
                            }
                        }

                    }

                    .blueShape {
                        display: none;
                    }

                    .mainCharacter {
                        display: none;
                    }

                }
            }
        }
    }

}

@media (max-width: 600px) {
    .App {
        .wrapperAlternate {
            .container {
                .mainSection {
                    height: 678px;
                    padding: 0 24px;
                    background-position-y: -200px;
                    background-image: url("../../../uiKit/assets/svg/blueShapeMobile.svg");

                }

                .stepsSection {
                    .stepsIntro {
                        .blueCircle {
                            display: none;
                        }
                    }

                    .steps {
                        width: 300px;
                        .vector01Mobile{
                            width: 264.5px;
                            top: -23px;
                        }
                        .vector02Mobile{
                            width: 170px;
                            top: 40px;
                        }
                        .vector03Mobile{
                            top: -24px
                        }
                        .vector04Mobile{
                            top: 20px;
                        }
                    }
                }
            }
        }
    }
}