@import "../../../uiKit/assets/colors/colors.scss";

.wrapper {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  background-image: url("../../../uiKit/assets/png/BG.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 48px;
  background-position: center center;
  position: relative;

  .hoverNav {
    &:hover {
      color: $blue500  !important;
    }
  }

  .container {
    position: relative;
    max-width: 1440px;
    width: 100%;

    .landingHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 164px;
      padding-right: 168px;

      .navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .signUpSection {
      margin-top: 130px;
      display: flex;
      justify-content: space-between;
      padding-left: 164px;
      padding-right: 116px;

      .textContainer {
        width: 49%;

        .landingSignUp {
          width: 172px;
          margin-bottom: 35px;
          margin-top: 62px;
        }
      }

      .imageContainer {
        margin-right: -24px;
        width: 42%;
      }
    }

    .groups {
      padding: 100px 0px 46px;
      display: flex;
      justify-content: center;

      .groupsContainer {
        width: 52%;
        display: flex;

        .newGroup {
          position: relative;
          width: 349px;
          height: 353px;
          margin-right: 50px;
          margin-bottom: 44px;
          cursor: pointer;
          border: 2px solid $blue050;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: $gray010;
        }

        .searchGroup {
          position: relative;
          width: 349px;
          height: 353px;
          margin-bottom: 44px;
          cursor: pointer;
          border: 2px solid $blue050;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: $gray010;
        }
      }
    }

    .howItWorks {
      padding: 96px 219px 0px;
      display: flex;
      align-items: center;

      .howItWorksText {
        margin-left: 9%;
        width: 65%;

        .howItWorksTitle {
          font-weight: 500;
          font-size: 48px;
          line-height: 67px;
          color: #3c3c3f;
          margin-bottom: 48px;

          span {
            font-weight: 700;
            font-size: 96px;
            line-height: 67px;
            color: #3c3c3f;
          }
        }
      }

      .campaignInBackground {
        width: 261px;
        height: 576px;
      }
    }

    .advantages {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 164px;
      padding-right: 166px;

      .advantagesBackground {
        background: linear-gradient(72.57deg,
            rgb(183, 219, 255) 11.7%,
            rgba(227, 239, 255, 0.43) 67.03%);
        border-radius: 50px;
        width: 100%;
        margin-top: 11%;
        display: flex;
        align-items: center;
        padding-top: 12.5%;
        padding-left: 5%;
        padding-bottom: 9%;
        position: relative;

        .imageBike {
          min-width: 491px;
        }

        .blueCircle {
          position: absolute;
          width: 87px;
          height: 87px;
          top: -102px;
          right: 12%;
          background: $blue300;
          border-radius: 50%;
          z-index: 10;
        }

        .grayBlueCircle {
          position: absolute;
          width: 66.73px;
          height: 68.03px;
          top: 78.83px;
          left: 50%;
          background: #b7dbff;
          border-radius: 50%;
          z-index: 10;
        }

        .yellowCircle {
          position: absolute;
          width: 32px;
          height: 32px;
          top: 103px;
          right: 4%;
          background: #fed43e;
          z-index: 10;
          border-radius: 50%;
        }

        .blueLittleCircle {
          position: absolute;
          width: 32.83px;
          height: 33.48px;
          background: $blue100;
          border-radius: 50%;
          bottom: -16.86px;
          left: 41%;
          z-index: 10;
        }
      }

      .advantagesSteps {
        position: relative;
        margin-top: 64px;
        width: 924px;

        .vector01 {
          position: absolute;
          top: 0px;
          left: 11%;
          height: 100%;
        }

        .vector01Mobile {
          display: none;
        }

        .vector02 {
          position: absolute;
          right: 0;
          bottom: -12px;
          height: 100%;
        }

        .vector02Mobile {
          display: none;
        }

        .vector03 {
          position: absolute;
          bottom: 18%;
          left: 0;
          height: 100%;
        }

        .vector03Mobile {
          display: none;
        }

        .vector04 {
          position: absolute;
          bottom: 16.7%;
          left: 11%;
          height: 100%;
        }

        .vector04Mobile {
          display: none;
        }
      }
    }

    .about {
      display: flex;
      flex-direction: column;
      padding: 120px 163px 166px;
      width: 100%;

      .aboutText {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .aboutTitle {
          width: 52%;
          padding-left: 48px;
        }
      }

      .aboutContent {
        width: 100%;
        display: flex;

        .photosLanding {
          padding-top: 60px;
          display: flex;
          width: 48%;
          min-width: 400px;
          padding-bottom: 60px;

          .photosContainer {
            position: relative;
            width: 400px;
            height: 350px;

            .laptop {
              position: absolute;
              top: 0px;
              left: 0px;
              z-index: 100;
              width: 269px;
              height: 260px;
              border-radius: 12px;
              box-shadow: rgba(84, 117, 200, 0.2) 0px 12px 32px;
            }

            .dialog {
              position: absolute;
              bottom: 0px;
              right: 0px;
              z-index: 10;
              width: 234px;
              height: 241px;
            }
          }
        }

        .featuresLanding {
          width: 52%;
          padding-left: 48px;
          min-width: 398px;
        }

        .joinNowLanding {
          margin-top: 24px;
          width: 172px;
        }
      }
    }

    .aboutGradient {
      padding: 140px 165px 80px;

      .background {
        background: linear-gradient(to left,
            rgb(183, 219, 255) 7.64%,
            rgba(207, 230, 255, 0.7) 95.84%);
        border-radius: 50px;
        max-width: 1110px;
        width: 100%;
        position: relative;

        .shtetlText {
          width: 100%;
          max-width: 540px;
          padding-top: 70px;
          margin-left: 134px;
          padding-bottom: 273px;
        }

        .shtetlImage {
          position: absolute;
          bottom: -13.43px;
          left: 16%;
          width: 84%;
          height: 260px;
          max-width: 700px;
        }
      }
    }

    .team {
      padding: 72px 164px;

      .ourTeam {
        display: flex;
        flex-direction: column;
        align-items: center;

        .cardsWrapper {
          width: 1110px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          .card {
            background: rgb(255, 255, 255);
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 42px;
            width: 255px;
            margin-right: 30px;
            margin-bottom: 25px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .contactUs {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding-top: 53px;
      margin-top: 63px;
      position: relative;

      .lightBlueCircle {
        width: 58px;
        height: 58px;
        background-color: $blue050;
        position: absolute;
        top: 0;
        right: 35%;
        border-radius: 50%;
      }

      .littleYellowCircle {
        width: 24px;
        height: 24px;
        background-color: #fff039;
        position: absolute;
        top: 30%;
        right: 58%;
        border-radius: 50%;
      }

      .bigYellowCircle {
        width: 40px;
        height: 40px;
        background-color: #fff039;
        position: absolute;
        bottom: 30%;
        right: 13%;
        border-radius: 50%;
      }

      .bigBlueCircle {
        width: 74px;
        height: 74px;
        background-color: #379aff;
        position: absolute;
        bottom: 0;
        left: 7%;
        border-radius: 50%;
      }

      .contactUsImage {
        display: none;
      }

      .contactUsDesktop {
        width: 260px;
        height: 618px;
        margin-right: 14%;
      }

      .sendMessageForm {
        width: 473px;
        padding-right: 72px;
        background-color: transparent;
        margin-right: 17%;
      }

      .questionsMobile {
        display: none;
      }
    }

    .footer {
      padding-top: 88px;
      padding-bottom: 60px;

      .footerMenu {
        display: flex;
        justify-content: space-between;
        padding-left: 164px;
        padding-right: 168px;
      }

      .dividerFooterMobile {
        display: none;
      }

      .dividerFooter {
        border: 1px solid rgb(223, 221, 221);
        background-color: rgb(223, 221, 221);
        height: 2px;
        margin: 31px 166px 31px 164px;
        width: calc(100% - 330px);
      }

      .connections {
        display: flex;
        justify-content: space-between;
        margin: 0px 166px 0px 164px;
        width: calc(100% - 330px);
      }

      .connectionsMobile {
        display: none;
      }

      .footerNavigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .policyContent {
      padding: 91px 302px 67px 166px;
    }
  }

}

.shortsWrapper {
  padding: 60px 0 0 164px
}

.mobileMenuLanding {
  display: flex;
  flex-direction: column;
  padding: 32px 24px 46px;
  height: 100%;
  justify-content: flex-start;
  align-items: center;

  .title {
    &:hover {
      background-color: $blue050  !important;
    }
  }
}

@media (max-width: 1300px) {
  .wrapper {
    .container {
      .contactUs {
        .bigBlueCircle {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .wrapper {
    .container {
      .landingHeader {
        padding: 0 24px;
      }

      .signUpSection {
        padding: 60px;
        padding-right: 0;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 24px);

        .textContainer {
          width: 100%;
        }

        .imageContainer {
          width: 100%;
        }
      }

      .groups {
        .groupsContainer {
          width: max-content;
          flex-direction: column;

          .newGroup {
            margin: 0;
            margin-bottom: 44px;
          }

          .searchGroup {
            margin: 0;
          }
        }
      }

      .howItWorks {
        padding: 60px;
      }

      .advantages {
        padding: 60px;

        .advantagesBackground {
          .imageBike {
            width: 80%;
            min-width: 350px;
          }
        }
      }

      .about {
        padding: 60px;

        .aboutText {
          display: flex;
          align-items: center;
          justify-content: center;

          .aboutTitle {
            width: 100%;
            padding-left: 0;
          }
        }

        .aboutContent {
          flex-direction: column;
          align-items: center;
        }
      }

      .aboutGradient {
        padding: 60px;

        .background {
          .shtetlText {
            margin-left: 70px;
          }

          .shtetlImage {
            left: 8%;
            width: 92%;
          }
        }
      }

      .team {
        padding: 60px;

        .ourTeam {
          .cardsWrapper {
            width: 600px;

            .card {
              &:last-child {
                margin-right: 30px;
              }
            }
          }
        }
      }

      .contactUs {
        background-image: none;
        display: flex;
        flex-direction: column;
        align-items: center;

        .lightBlueCircle {
          right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 29px;
          border-bottom-left-radius: 29px;
          width: 29px;
        }

        .littleYellowCircle {
          left: 0;
          width: 16px;
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .bigYellowCircle {
          top: 35%;
          right: 5%;
        }

        .bigBlueCircle {
          display: block;
          top: 60%;
          left: 0;
          width: 40px;
          border-top-right-radius: 40px;
          border-bottom-right-radius: 40px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .contactUsImage {
          width: 260px;
          height: 618px;
          display: flex;
        }

        .contactUsDesktop {
          display: none;
        }

        .sendMessageForm {
          display: flex;
          flex-direction: column;
          margin-right: 0;
          margin-top: 40px;
        }
      }

      .footer {
        .footerMenu {
          padding: 0 24px;
        }

        .dividerFooter {
          margin: 31px 24px;
          width: calc(100% - 48px);
        }

        .connections {
          margin: 0 24px;
          width: calc(100% - 48px);
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    .container {
      .advantages {
        .advantagesSteps {
          width: 694px;

          .vector01 {
            width: 70%;
            top: 20px;
            left: 75px;
          }

          .vector02 {
            width: 55%;
            bottom: -10px;
          }

          .vector03 {
            width: 60%;
            bottom: 19%;
            left: -7%;
          }

          .vector04 {
            bottom: 23%;
          }
        }

        .padding4Vector {
          padding-bottom: 8% !important;
        }
      }

      .landingHeader {
        .navigation {
          display: none;
        }
      }
      
      .contactUs {
        .bigBlueCircle {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .wrapper {
    .container {
      .signUpSection {
        padding-left: 24px;
        margin-top: 56px;

        .textContainer {
          .landingSignUp {
            max-width: 326px;
            width: 100%;
            margin-bottom: 72px;
          }
        }
      }

      .groups {
        .groupsContainer {
          .newGroup {
            width: 327px;
            height: 345px;
          }

          .searchGroup {
            width: 327px;
            height: 335px;
          }
        }
      }

      .howItWorks {
        padding: 0 24px;
        flex-direction: column-reverse;

        .howItWorksText {
          margin-left: 0;
          width: 100%;

          .howItWorksTitle {
            font-weight: 600;
            font-size: 40px;
            line-height: 52px;
            margin-bottom: 40px;

            span {
              font-size: 72px;
              line-height: 67px;
            }
          }
        }

        .campaignInBackground {
          margin-top: 56px;
        }
      }

      .advantages {
        padding: 0 8.5px 60px 8.5px;

        .advantagesBackground {
          flex-direction: column;
          padding-top: 140px;

          .blueCircle {
            top: -37px;
            right: 5px;
          }

          .grayBlueCircle {
            top: 18px;
            left: 25px;
          }

          .yellowCircle {
            top: 316px;
            right: 35px;
          }

          .blueLittleCircle {
            bottom: -17px;
            left: 114px;
          }
        }

        .advantagesSteps {
          width: 427px;

          .vector01 {
            display: none;
          }

          .vector01Mobile {
            position: absolute;
            top: -10%;
            left: 11%;
            display: block;
            height: 100%;
            width: 325px;
          }

          .vector02 {
            display: none;
          }

          .vector02Mobile {
            position: absolute;
            top: -10%;
            left: 10%;
            display: block;
            height: 100%;
            width: 190px;
          }

          .vector03 {
            display: none;
          }

          .vector03Mobile {
            position: absolute;
            top: 1%;
            right: 14%;
            display: block;
            height: 100%;
            width: 163px;
          }

          .vector04 {
            display: none;
          }

          .vector04Mobile {
            position: absolute;
            top: -17%;
            right: 14%;
            display: block;
            height: 100%;
            z-index: 10;
            width: 312px;
          }
        }

        .padding4Vector {
          padding-bottom: 10% !important;
          width: 57% !important;
        }
      }

      .about {
        padding: 24px;

        .aboutContent {
          .joinNowLanding {
            width: 100%;
          }
        }
      }

      .aboutGradient {
        padding: 0 8px;

        .background {
          .shtetlText {
            margin-left: 16px;
          }

          .shtetlImage {
            height: 160px;
            left: 20%;
            width: 80%;
            max-width: 520px;
            bottom: -7.5px;
          }
        }
      }

      .team {
        padding: 60px 24px;
      }

      .contactUs {
        .sendMessageForm {
          .questionsMobile {
            display: block;
          }
        }
      }

      .footer {
        .connectionsMobile {
          display: flex;
          margin: 0 24px;
          justify-content: space-between;
          width: calc(100% - 48px);
        }

        .connections {
          display: none;
        }
      }

      .policyContent {
        padding: 28px 24px 0 24px;
      }
    }
  }

  .shortsWrapper{
    padding: 24px;
  }
}

@media (max-width: 700px) {
  .wrapper {
    .container {
      .team {
        .ourTeam {
          .cardsWrapper {
            width: 255px;
            margin-right: 0;

            .card {
              margin-right: 0;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      .advantages {
        .advantagesBackground {
          align-items: baseline;

          .title {
            max-width: 236px;
          }
        }
      }

      .about {
        .aboutContent {
          .photosLanding {
            min-width: 315px;

            .photosContainer {
              width: 315px;
              height: 270px;

              .laptop {
                width: 207px;
                height: 200px;
              }

              .dialog {
                width: 180px;
                height: 185px;
              }
            }
          }

          .featuresLanding {
            padding-left: 0;
            min-width: 326px;
          }
        }
      }

      .aboutGradient {
        .background {
          .shtetlText {
            max-width: 327px;
          }

          .shtetlImage {
            height: 120px;
            left: 5%;
            width: 95%;
            max-width: 327px;
          }
        }
      }

      .contactUs {
        .sendMessageForm {
          padding-right: 0;
          width: 100%;
          min-width: 327px;
          padding: 0 24px;

          .questionsDesktop {
            display: none;
          }
        }

        .questionsMobile {
          display: block;
          max-width: 100%;
        }
      }

      .footer {
        .dividerFooterMobile {
          display: block;
          border: 1px solid #dfdddd;
          background-color: #dfdddd;
          height: 2px;
          margin: 31px 0;
          width: 100%;
        }

        .footerMenu {
          flex-direction: column;
        }

        .footerNavigation {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .wrapper {
    .container {
      .advantages {
        .advantagesSteps {
          width: 300px;

          .vector01Mobile {
            width: 267px;
          }
          .vector02Mobile {
            width: 60%;
            left: 1.5%;
            top: -14%;
          }

          .vector03Mobile {
            width: 58%;
            right: 0;
          }

          .vector04Mobile {
            top: -10%;
            right: 0;
          }
        }

        .padding4Vector {
          width: 70% !important;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .wrapper {
    .container {
      .groups {
        .groupsContainer {
          .newGroup {
            width: 270px;
            height: 290px;
          }

          .searchGroup {
            width: 270px;
            height: 280px;
          }
        }
      }

      .advantages {
        .advantagesBackground {
          .imageBike {
            min-width: 270px;
          }
        }
      }

      .about {
        .aboutContent {
          .photosLanding {
            min-width: 265px;

            .photosContainer {
              width: 275px;
              height: 230px;

              .laptop {
                width: 177px;
                height: 170px;
              }

              .dialog {
                width: 155px;
                height: 160px;
              }
            }
          }

          .featuresLanding {
            min-width: 265px;
          }
        }
      }

      .aboutGradient {
        .background {
          .shtetlText {
            max-width: 270px;
          }

          .shtetlImage {
            max-width: 270px;
            bottom: -6px;
          }
        }
      }

      .contactUs {
        .bigBlueCircle {
          display: none;
        }

        .bigYellowCircle {
          display: none;
        }
      }
    }
  }
  .shortsWrapper{
    padding: 8px;
  }
}

.downWave {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  background-image: url("../../../uiKit/assets/svg/downWave.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  z-index: 10;
}

.blueAreaMobile {
  display: none;
}

.signUpButton {
  width: 100%;
  margin-bottom: 8px;
}

.blueAreaMobile {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-image: url("../../../uiKit/assets/svg/blueAreaMobile.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  z-index: 10;
}

.verificationTextWrapper {
  width: 368px;
  margin-bottom: 24px;
}

.verificationImage {
  display: flex;
  align-items: center;
  margin-left: 66px;
}

@media (min-width: 600px) and (max-width: 1200px) {
  .verificationTextWrapper {
    width: 100%;
    margin-bottom: 74px;
  }

  .verificationImage {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .signUpBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    background-image: url("../../../uiKit/assets/svg/signUpBackground.svg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    z-index: 10;
  }

  .downWave {
    display: none;
  }

  .verificationTextWrapper {
    width: 100%;
  }

  .verificationImage {
    display: flex;
    align-items: center;
    margin-left: 60px;
  }

  .createAccountInputWrapper {
    padding: 20px 16px;
    margin-bottom: 12px;
    background: $gray010;
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
    border-radius: 12px;
  }
}