.findCommunityWrapper {
  max-width: 400px;
  min-width: 312px;
  width: 100%;
  min-height: 394px;
  height: 100%;
  z-index: 1000;
}

.communityCards {
  overflow: auto;
  position: relative;
}

.findCommunityContent {
  height: 450px;
}

.find {
  width: 100%;
  height: 100%;
  margin-top: 72px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mobileHeaderFindCommunity {
  display: none;
}

.findCommunityTitleMobile {
  display: none;
  margin: 8px 0 20px 0;
}

@media (max-width: 900px) {
  .findCommunityWrapper {
    height: 100vh;
    margin: 60px 24px 0px 24px;
    .find {
      display: none;
    }
  }

  .mobileHeaderFindCommunity {
    display: flex;
    width: 100%;
    .menu {
      width: 100%;
    }
  }

  .findCommunityTitleMobile {
    display: flex;
  }

  .contentWrapperSearch {
    height: auto;
  }
}
