.findCommunitySidebarWrapper {
  max-width: 472px;
  min-width: 312px;
  width: 100%;
  padding: 60px;
}


@media (max-width: 900px) {
  .findCommunitySidebarWrapper {
    max-width: 400px;
    padding: 24px
  }
}