@import "../../uiKit/assets/colors/colors.scss";

.sidebar {
  min-width: 328px;
  height: 100%;
}

.communityMenu {
  margin: 48px 0 20px 0;
}

.mainLogo {
  margin: 40px 16px 36px 16px;
  cursor: pointer;
}

.menuIcon {
  width: 20px;
  height: 16px;
  display: flex;
  align-items: center;
}

.sidebarTitle {
  margin: 0 16px 2px 16px;
  padding: 8px 24px;
}

@media (max-width: 900px) {
  .sidebar {
    display: none;
  }

  .sidebarMobile {
    flex: 1 0 auto;
  }

  .communityMenu {
    margin: 0 0 32px 0;
  }

  .personalSettings {
    margin: 32px 0 20px 0;
  }
  .sidebarTitle{
    margin: 8px 24px 20px 24px;
    padding: 0;
  }
}
