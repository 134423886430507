@import "./uiKit/assets/colors/colors.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.App {
  min-height: 100vh;
  font-family: Poppins;
  position: relative;
  scroll-behavior: smooth;
}

.hidden {
  display: none;
}

.displayContent {
  display: flex;
}

.page {
  position: relative;
  width: 464px;
  min-width: 320px;
  height: max-content;
  padding: 48px;
  background: $gray010;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
  border-radius: 12px;
  z-index: 100;
}

.MuiChip-deletable {
  background-color: $blue100 !important;
  color: $gray010 !important;

  .MuiChip-deleteIconColorDefault {
    color: $gray010 !important;
  }
}

.photoWrapper {
  background-color: #b7dbff;
  border-radius: 10px;
  height: 260px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.doubleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputWrapper {
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 12px;
  background: $gray010;
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
}

.errorMessage {
  color: $red900;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  min-height: 28px;
}

.inputDescription {
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 28px;
  color: $gray800;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  min-height: 28px;
}

.menuItem {
  display: flex;
  padding: 10px 24px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.scrollTop {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  border-radius: 50%;
  background-color: $blue500;
  justify-content: center;
  align-items: center;
}

.ourValues {
  display: flex;
  max-width: 900px;
  margin: auto;
  flex-wrap: wrap;
  padding: 24px;
  margin-top: 90px;
  margin-bottom: 120px;
  justify-content: space-around;
}

.ourValues {
  &>div {
    width: 425px;
    padding: 16px;
  }
}

.ourValuesUser {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 24px 40px 40px 40px;
  width: calc(100% - 80px);
  max-width: 872px;
  padding: 32px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
  border-radius: 12px;
}

.ourValuesUser {
  &>div {
    width: 300px;
    padding: 16px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.4;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.6;
  }
}

.selected {
  background-color: $blue050;
  border-radius: 12px;
}

.divider {
  border: 1px solid $gray300;
  background-color: $gray300;
  height: 2px;
  margin: 24px 0;
  width: 100%;
}

.boxShadow {
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
}

.margin24To0 {
  margin-right: 24px;
}

.marginTop24To0 {
  margin-top: 24px;
}

.mr16to0 {
  margin-right: 16px;
}

ul {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

.padding48 {
  padding: 48px;
}

.blueBackground {
  width: 100%;
  min-height: 100vh;
  background-color: $blue010;
}

.whiteBackground {
  background-color: $blue010;
}

input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.buttonDescription {
  display: flex;
  margin-top: 16px;
}

.memberSearch {
  .customInput {
    &>div {
      margin-bottom: 0px;
    }
  }
}

.margin48to24 {
  margin-top: 48px;
}

.formWrapper {
  margin: 40px;
}

.accountNext {
  margin-top: 40px;
}

@media (max-width: 900px) {
  .page {
    width: 100%;
    padding: 40px 24px 24px 24px;
    background: $blue010;
    box-shadow: none;
  }

  .inputWrapper {
    background-color: $gray010;
    padding: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
    border-radius: 12px;
  }

  .margin24To0 {
    margin-right: 0;
  }

  .displayContent {
    display: none;
  }

  .padding48 {
    padding: 16px;
  }

  .menuItem {
    padding: 12px 20px;
  }
}

@media (max-width: 600px) {
  .buttonsWrapper {
    width: 100%;
    margin: 40px 0;
  }

  .whiteBackground {
    background-color: $gray010;
  }

  .marginTop24To0 {
    margin-top: 0;
  }

  .formWrapper {
    margin: 0;
  }

  .margin48to24 {
    margin-top: 24px;
  }

  .mr16to0 {
    margin-right: 0;
  }
}

@media (max-width: 500px) {
  .page {
    min-width: 250px;
  }

  .photoWrapper {
    height: 180px;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }
}

@media (max-width: 350px) {
  .photoWrapper {
    height: 180px;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }
}