.settingsWrapper {
  margin: 0 16px;
  background-color: white;
  padding: 8px 0;
}

.iconWrapper{
    padding-right: 16px; 
}

@media (max-width: 900px) {
  .settingsWrapper {
    margin: 12px 24px 40px 24px;
    border-radius: 10px;
  }

  .iconWrapper{
      padding-left: 0;
  }
}
