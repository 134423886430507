
.feeWrapper {
  margin-top: 16px;
}

@media (max-width: 900px) {
  .feeWrapper {
    margin-bottom: 24px;
    margin-top: 16px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 8px;
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
  }
}
