@import "../../../uiKit/assets/colors/colors.scss";

.securityPhotoWrapper {
  background-color: $pink;
  border-radius: 10px;
  height: 260px;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
}

.loginInputs {
  max-width: 373px;
  width: 100%;

  .currentPasswordText {
    margin-bottom: 20px;
  }

  .inputRow {
    display: flex;
  }
}

.securityButtons {
  margin-top: 8px;
  display: flex;
  .button {
    width: 150px;
  }
}

@media (max-width: 900px) {
  .securityPhotoWrapper {
    height: 180px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .loginInputs {
    padding: 16px 16px 0 16px;
    max-width: 100%;
    background-color: $gray010;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .currentPasswordText {
      margin-bottom: 12px;
    }

    .inputRow {
      .customInput {
        flex: 1;
      }
    }
  }

  .securityButtons {
    flex-direction: column;
    .button {
      width: 100%;
      margin-top: 16px;
    }
  }

  .borderNone {
    border: none !important;
  }
}
