@import "../../../uiKit/assets/colors/colors.scss";

.personalWrapper {
  width: 664px;
  margin: 40px 40px 0 40px;

  .personalInfoContent {
    margin-top: 140px;
    position: relative;
    background-color: $gray010;
    padding: 50px 16px 16px;
    margin-bottom: 24px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);

    .imageWrapper {
      position: absolute;
      top: -105px;
      left: 16px;
    }

    .textWrapper {
      background-color: $blue100;
      border-radius: 10px;
      height: 140px;
      width: 140px;
      position: relative;
      border-radius: 50%;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $gray010;
      font-size: 42px;
      font-weight: 600;
      font-family: "Poppins";
    }

    .personalInfoInputWrapper {
      max-width: 320px;
      width: 100%;
    }

    .deleteMemberButton {
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $blue300;
      font-style: normal;
      align-items: center;
      font-family: "Poppins";
    }
  }

  .personalInfoButtons {
    display: flex;
    margin-bottom: 60px;
    .infoButtonCancel {
      width: 124px;
    }
    .infoButtonSave {
      width: 124px;
      margin-left: 16px;
    }
  }
}

@media (max-width: 1200px) {
  .personalWrapper {
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
    .personalInfoContent {
      .imageWrapper {
        left: 25%;
      }
    }

    .personalInfoButtons {
      flex-direction: column-reverse;
      .infoButtonCancel {
        width: 100%;
      }
      .infoButtonSave {
        width: 100%;
        margin-left: 0;
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: 900px) {
  .personalWrapper {
    .personalInfoContent {
      .imageWrapper {
        left: 25%;
      }
    }
  }
}
