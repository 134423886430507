@import "../../../uiKit/assets/colors/colors.scss";

.campaignDetails {
  background-color: white;
}

.recipient {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;

  .icon {
    display: none;
  }
}

.recipientCompleted {
  display: flex;
  margin-bottom: 8px;

  .icon {
    display: none;
  }
}

.transferButtons {
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;
  width: fit-content;

  .mobileButton {
    display: none;
  }
}

.margin16to0 {
  margin-left: 16px;
}

.donationTable {
  position: relative;
  margin-top: 0;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
  border-radius: 12px;

  .tableHeader {
    tr {
      th {
        &:last-child {
          padding-right: 16px;
        }
      }
    }

  }
}

.campaignProgressBar {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .progressBar {
    width: 100%;
  }

  .progressBarButton {
    margin-left: 48px;
  }
}

.donationListWrapper {
  display: none;
}

.recentHeader {
  margin-bottom: 24px;
}

.donationWrapper {
  position: relative;
  margin-top: 24px;
  margin-left: 40px;
  padding: 48px;
  width: 429px;
  background-color: $gray010;
  border-radius: 12px;
}

.descriptionShowMore {
  color: rgb(55, 154, 255);
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0px 8px;
  width: 210px;
  cursor: pointer;
}

.campaignTitleMargin {
  margin-bottom: 8px;
  margin-top: 24px;
}

.photoWrapperCampaign {
  width: 100%;
  background-color: #b7dbff;
  border-radius: 10px;
  height: 260px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.photoWrapperCompletedCampaign {
  width: 100%;
  background-color: #b7dbff;
  border-radius: 10px;
  height: 260px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #379AFF;
    opacity: 0.8;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 900px) {
  .campaignProgressBar {
    flex-direction: column;

    .progressBar {
      width: 100%;
    }

    .progressBarButton {
      margin-left: 0;
      width: 100%;
    }
  }

  .transferButtons {
    flex-direction: column;
    width: 100%;

    >div {
      margin-bottom: 16px;
    }

    .mobileButton {
      display: flex;
    }
  }

  .margin16to0 {
    margin-left: 0;
  }

  .donationTable {
    display: none;
  }

  .donationListWrapper {
    display: flex;
    flex-direction: column;
    margin: 24px 0;

    .donationList {
      box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
      border-radius: 12px;

      .donationListRow {
        display: flex;
        justify-content: space-between;
        padding: 16px 16px 12px;
        background-color: $gray010;
        border-bottom: 1px solid $gray100;

        &:first-child {
          border-radius: 12px 12px 0 0;
        }

        &:last-child {
          border-radius: 0 0 12px 12px;
        }
      }
    }
  }

  .recentHeader {
    margin-top: 16px;
  }

  .donationWrapper {
    margin: 0;
    margin-top: 40px;
    padding: 24px;
    width: 100%;
    background-color: $blue010;
    box-shadow: none;
  }

  .descriptionShowMore {
    background-color: $blue010;
  }

  .recipient {
    .icon {
      display: flex;
      width: 16px;
      height: 20px;
    }

    .word {
      display: none;
    }
  }

  .recipientCompleted {
    .icon {
      display: flex;
      width: 16px;
      height: 20px;
    }

    .word {
      display: none;
    }
  }

  .campaignTitleMargin {
    margin-bottom: 0;
    margin-top: 4px;
  }
}

@media (max-width: 500px) {
  .photoWrapperCampaign {
    height: 180px;
    background-position-y: bottom;
    background-repeat: no-repeat;
  }

  .photoWrapperCompletedCampaign {
    height: 180px;

    img {
      background-position-y: bottom;
      background-repeat: no-repeat;
    }
  }
}