@import "../../../uiKit/assets/colors/colors.scss";

.photoUpload {
  margin: 24px 0;
  background-color: $blue020;
  border-radius: 10px;
  height: 180px;
  position: relative;
}

.inputWrapperCommunity {
  border-radius: 10px; 
  background: $gray010;
  padding: 20px 0;
  border-radius: 12px;
}

.createdPage {
  width: 100%;
  padding: 40px 48px 48px 48px;
  height: max-content;
  position: relative;
  width: 464px;
  background: $gray010;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
  border-radius: 12px;
  z-index: 100;
}

.menuVersion{
  margin: 40px;
}

@media (max-width: 900px) {
  .photoUpload{
    height: 160px;
  }
  .inputWrapperCommunity {
    background-color: $gray010;
    padding: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
  .createdPage {
    width: 100%;
    box-shadow: none;
    height: 100vh;
    padding: 120px 24px 64px 24px;
  }
  
  .menuVersion{
    margin: 0;
    margin-bottom: 60px;
  }
}
