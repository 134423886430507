.draftsProgressbar {
  position: relative;
  margin: 32px 40px;
  max-width: 648px;
  min-width: 276px;
  width: 100%;
  .page {
    margin-bottom: 32px;
    svg {
      width: 160px;
      height: 200px;
    }
  }
}

@media (max-width: 1200px) {
  .draftsProgressbar {
    margin: 20px 24px;
    width: calc(100% - 48px);
    
  }
}
