@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  background-color: #FFFFFF;
  margin: 0;
}
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}