@import "../../uiKit/assets/colors/colors.scss";

.verified {
  display: flex;
  border: 1px solid $green100;
  border-radius: 20px;
  max-width: 90px;
  width: 100%;
}

.approved {
  display: flex;
  border: 1px solid $green100;
  border-radius: 20px;
  width: fit-content;
}

.notVerified {
  display: flex;
  border: 1px solid $red600;
  border-radius: 20px;
  max-width: 110px;
  width: 100%;
}

.notApproved {
  display: flex;
  border: 1px solid $red600;
  border-radius: 20px;
  width: fit-content;
}

.status {
  background-color: $gray010;
  height: 20px;
  align-items: center;
  padding: 0 12px;
  font-size: 12px;
}