.subscribeButton {
  width: 128px;
}

.dividerSubscription{
  border: 1px solid #dfdddd;
  background-color: #dfdddd;
  height: 2px;
  margin: 12px 0;
  width: 100%;
}

.communityInfoHeaderWrapper{
  .checkboxesWrapper{
    position: relative;
  }
}

@media (max-width: 900px) {
  .subscribeButton {
    width: 100%;
  }

  .communityInfoHeaderWrapper {
    .checkboxesWrapper {
      width: 100%;
      padding: 16px;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
      border-radius: 12px;
    }
  }
}