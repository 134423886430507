@import "../../../uiKit/assets/colors/colors.scss";

.mobileMenu {
  display: none;
}

@media (max-width: 1000px) {
  .mobileMenu {
    display: flex; 
  }
  .mobileMenuHeader {
    display: flex;
    justify-content: space-between;
    padding: 42px 36px 18px 24px;
    align-items: center;
    .mobileMenuTitle {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: $gray900;
      line-height: 20px;
    }
  }
}
