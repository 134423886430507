@import "../../uiKit/assets/colors/colors.scss";

.memberInfoWrapper {
  width: 664px;
  margin: 40px 40px 0 40px;
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
  position: relative;

  .memberInfoContentWrapper {
    margin-top: 140px;
    position: relative;
    background-color: rgb(255, 255, 255);
    padding: 50px 16px 16px;
    margin-bottom: 24px;
    border-radius: 12px;

    .imageWrapper {
      position: absolute;
      top: -105px;
      left: 16px;
    }

    .textWrapper {
      background-color: $blue100;
      border-radius: 10px;
      height: 140px;
      width: 140px;
      position: relative;
      border-radius: 50%;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $gray010;
      font-size: 42px;
      font-weight: 600;
      font-family: "Poppins";
    }

    .deleteMemberButton {
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $blue300;
      font-style: normal;
      align-items: center;
      font-family: "Poppins";
    }
  }
}

.userInfo {
  display: flex;
  padding: 12px 0;
}

@media (max-width: 1200px) {
  .memberInfoWrapper {
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
    margin-bottom: 60px;

    .memberInfoContentWrapper {
      .imageWrapper {
        left: 35%;
      }
    }
  }
}

@media (max-width: 900px) {
  .memberInfoWrapper {
    .memberInfoContentWrapper {
      .imageWrapper {
        left: 25%;
      }
    }
  }
}