@import "../../uiKit/assets/colors/colors.scss";

.inputFile{
    width: 36px;
    height: 36px;
    background-color: $blue300;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 18px;
    margin-top: -18px;
    margin-left: -18px;
}

.inputFileCorner {
    width: 36px;
    height: 36px;
    background-color: $blue300;
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 18px;
    margin-top: -18px;
    margin-left: -18px;
}

.inputFileCircle{
    width: 36px;
    height: 36px;
    background-color: $blue300;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 18px;
}

.deleteButton{
    width: 36px;
    height: 36px;
    background-color: $blue300;
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 18px;
    margin-top: -18px;
    margin-left: -18px;
    cursor: pointer;
}

.deleteButtonCircle{
    width: 36px;
    height: 36px;
    background-color: $blue300;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 18px;
    cursor: pointer;
}

.inputFile>input[type="file"]{
    display: none;
}

.inputFileCorner>input[type="file"]{
    display: none;
}

.inputFileCircle>input[type="file"]{
    display: none;
}