.attachments {
  display: flex;
  color: #2087ef;
  border: 1px solid;
  background: #ffffff;
  font-family: Poppins;
  border-color: #379aff;
  border-radius: 10px;
  text-transform: none;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 10px 20px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  span {
    margin-left: 10px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.attachmentDisabled {
  display: flex;
  color: #7dbeff;
  border-color: #b7dbff;
  justify-content: center;
  cursor: default;
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 10px 20px 10px 20px;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid;
  background: #ffffff;
  font-family: Poppins;
  border-radius: 10px;
  text-transform: none;
  span {
    margin-left: 10px;
    font-weight: 500;
    white-space: nowrap;
  }
}

.contactusInputs {
  display: flex;
  .contactusEmail {
    margin-right: 24px;
    max-width: 320px;
    width: 100%;
  }
}

.contactUsButtons {
  display: flex;
}

.contactusSendMobile{
    display: none;
}

@media (max-width: 900px) {
  .contactusInputs {
    flex-direction: column;
  }

  .contactusSend {
    display: none;
  }
  .contactusMobileWrapper {
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 24px;
  }

  .contactUsButtons {
    display: block;
  }

  .contactusSendMobile{
      display: block;
      margin-bottom: 60px;
  }
}
