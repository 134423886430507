@media (max-width: 600px) {
  .loginBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    background-image: url("../../uiKit/assets/svg/upWave.svg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    z-index: 10;
  }
}
