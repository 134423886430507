.communityWrapper {
  background-color: white;
  margin: 0 16px;

  .headerWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 24px;
    justify-content: space-between;
    .titleWrapper {
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
      }
      div {
        padding-left: 12px;
      }
    }
    .adminMenu {
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
  }
  .contentWrapper {
    padding: 8px 0;
    .customSelect{
      padding-left: 24px;
      margin-bottom: 9px;
    }
  }
}

@media (max-width: 900px) {
  .communityWrapper {
    padding: 0;
    margin: 12px 24px 0 24px;
    border-radius: 10px;
    .contentWrapper {
      padding: 20px 0 8px 0;
      .customSelect{
        padding-left: 20px;
      }
    }
    .headerWrapper{
      padding: 0 20px;
    }
  }
}
