@import "../../uiKit/assets/colors/colors.scss";

.ourBalanceDescription {
  margin: 20px 0px;
}

.ourBalanceTitle {
  margin-top: 16px;
  margin-bottom: 20px;
}

.photoWrapperOurBalance {
  width: 100%;
  background-color: #f8ecad;
  border-radius: 10px;
  height: 260px;
  position: relative;
  background-size: contain;
  background-position: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
}

@media (max-width: 900px) {
  .photoWrapperOurBalance {
    height: 180px;
  }

  .ourBalanceDescription {
    margin: 16px 0px;
  }

  .donationListOurBalanceRow {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 12px;
    background-color: $gray010;
    border-bottom: 1px solid $gray100;
    &:first-child {
      border-radius: 12px 12px 0 0;
    }
    &:last-child {
      border-radius: 0 0 12px 12px;
    }
  }

  .transferInfoIcon {
    width: 18px;
    height: 14px;
    display: flex;
    align-items: center;
  }
}
