@import "../../uiKit/assets/colors/colors.scss";

.membersWrapper {
  margin: 24px 40px 40px 40px;
  width: calc(100% - 80px);
  max-width: 872px;

  .photoUploadMembers {
    background-color: $blue020;
    border-radius: 10px;
    height: 260px;
    position: relative;
  }

  .membersList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .listMobile {
      display: none;
    }
  }

  .membersTableWrapper {
    position: relative;
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
    background-color: $gray010;
    border-radius: 12px;
  }

  .communityInfoWrapper {
    box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
    background-color: $gray010;
    border-radius: 12px;
    padding: 32px;
    margin-bottom: 24px;

    .flexMargin {
      display: flex;
      margin-top: 12px;
    }
  }
}

.displayIcon {
  margin-right: 12px;
}

.memberMobile {
  display: flex;
  margin-top: 15px;
  width: 100%;
  cursor: pointer;
  height: 40px;
  &:first-child {
    margin-top: 0;
  }
}

@media (max-width: 1200px) {
  .membersWrapper {
    margin: 24px;
    width: calc(100% - 48px);

    .communityInfoWrapper {
      display: flex;
      flex-direction: column-reverse;
      box-shadow: none;
      background-color: $blue010;
      padding: 0;
      margin-bottom: 0;
      .flexMargin {
        margin: 8px 0 16px 0;
      }
    }

    .membersTableWrapper {
      display: none;
    }

    .membersList {
      flex-direction: column;
      .memberSearch {
        margin: 20px 0 4px 0;
        width: 100%;
      }
      .listMobile {
        margin: 16px 0 42px 0;
        display: flex;
        width: 100%;
        flex-direction: column;
      }
    }
    .memberButton {
      width: 100%;
    }
  }
  .displayIcon {
    display: none;
  }
}
