@import "../../uiKit/assets/colors/colors.scss";

.addMembersFormWrapper {
  .memberContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .inputWrapper {
      width: 320px;
      margin-top: 16px;
    }

    .removeMember {
      display: inline-block;
      font-size: 14px;
      color: $blue500;
      line-height: 20px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .addMembers {
    display: inline-block;
    margin-top: 12px;
    margin-bottom: 32px;
  }

  .addMemberButtons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .addMemberButton {
      width: 320px;
    }
  }
}

@media (max-width: 900px) {
  .addMembersFormWrapper {
    .addMemberButtons {
      flex-direction: column-reverse;
      .addMemberButton {
        width: 100%;
      }
    }
  }
}
