@import "../../uiKit/assets/colors/colors.scss";

.progressWrapper {
  background-color: $gray010;
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 16px;
  position: relative;
}
