@import "../../uiKit/assets/colors/colors.scss";

.communityInfoHeaderWrapper {
  width: calc(100% - 80px);
  max-width: 872px;
  margin: 24px 40px 40px 40px;
  padding: 32px;
  background-color: $gray010;
  box-shadow: 0px 4px 10px rgba(224, 238, 242, 0.8);
  border-radius: 12px;

  .communityInfoHeaderPhoto {
    border-radius: 12px;
    max-width: 808px;
    width: 100%;
    height: 260px;
    background-color: $blue010;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  }
}

.maxContentButton {
  width: max-content;
  margin-top: 24px;
}

.campaignsWrapper {
  max-width: 872px;
  width: calc(100% - 80px);
  margin: 40px;

  .activeCampaigns {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: $gray800;
    padding: 8px 16px;
    background-color: $blue050;
    border-radius: 12px;
    margin-right: 16px;
    cursor: pointer;
  }

  .deactiveCampaigns {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: $gray800;
    padding: 8px 16px;
    background-color: $blue010;
    border-radius: 12px;
    margin-right: 16px;
    cursor: pointer;
  }

  .userActiveCampaigns {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.desktopProgressBar {
  display: flex;
  flex-direction: column;
  position: relative;
}

.campaignButton {
  display: flex;
  margin-bottom: 24px;
}

.mobileProgressBar {
  display: none;
}

.memberButtons {
  display: flex;
  width: fit-content;
}

.marginForButton {
  margin-left: 16px;
}

.campaignWrapperNotLoged{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  z-index: 1000;
  .communityInfoHeaderWrapper{
    margin-left: 0;
  }
}

@media (max-width: 900px) {
  .communityInfoHeaderWrapper {
    width: calc(100% - 48px);
    background-color: $blue010;
    box-shadow: none;
    padding: 0;
    margin: 24px 24px 60px 24px;

    .communityInfoHeaderPhoto {
      margin-bottom: 16px;
    }
  }
  .campaignsWrapper {
    width: calc(100% - 48px);
    margin: 24px;
    margin-bottom: 60px;
  }

  .campaignMobileHeader {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 16px;
  }

  .maxContentButton {
    width: 100%;
  }

  .desktopProgressBar {
    display: none;
  }

  .mobileProgressBar {
    display: flex;
  }

  .campaignButton {
    display: none;
  }

  .memberButtons {
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
  }

  .marginForButton {
    margin-left: 0;
    margin-top: 16px;
  }

  .displayNone {
    display: none;
  }

  .campaignWrapperNotLoged{
    .communityInfoHeaderWrapper{
      margin-left: 24px;
    }
  }
}
